.container{
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr;
  gap: 0.5em;
}
.title{
  font-weight: bold;
  font-size: 1.10em;
}
input.item,
select.item {
  min-width: 0;
}
.item {
  display: grid;
  justify-content: left;
  align-items: center;
  font-size: 1em;
  padding: 0.5em;
}
.btn-add{
  display: flex;
  align-items: center;
  font-size: 1.15em;
  margin-left: 0;
}
.btn-modify{
  display: flex;
  align-items: center;
  font-size: 1em;
  margin: auto;
  margin-left: 0;
}
.form label{
  margin-top: 1.5em;
  display: block;
  float: left;
  color: black;
}
.form input,
.form textarea,
.form select {
  font-family: "Roboto", sans-serif;
  padding: 8px 6px;
  font-size: 1em;
  box-sizing:border-box;
  width: 100%;
}
.form .checkbox{
  display: grid;
  grid-template-columns: 15px 1fr;
  column-gap: 0.5em;
  align-items: baseline;
}
.error{
  font-size: 0.85em;
  text-align: left;
  color:red;
  margin: 0;
}
.checkbox label{
  margin-right:auto;
}
.download-container{
  display: flex;
  align-items: center;
}
.download-button{
  display: flex;
  align-items: center;
}
.download-write{
  margin: 0;
  margin-left: 5px;
}
.container:hover{
  background-color: rgba(200, 200, 200, 0.250);
}
.title:hover,
.filter:hover{
  background-color: transparent;
}

@media screen and (max-width: 1050px) {
  .filter {
    grid-template-rows: repeat(7, 1fr);
    grid-template-columns: 1fr;
  }
  input.item,
  select.item {
    width: 100%;
    box-sizing: border-box;
  }
}
